//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formResourceList } from '@/api/modular/flowable/formResourceManage'
import { flowableDefinitionUserTasks } from '@/api/modular/flowable/defenitionManage'
import { formEdit } from '@/api/modular/flowable/formManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      formListData: [],
      recordData: [],
      nodeTypeData: [],
      actData: [],
      actIdShow: false,
      actIdRequired: false
    }
  },
  methods: {
    /**
     * 打開界面初始化
     */
    edit (record, nodeType) {
      this.recordData = record
      this.nodeTypeData = nodeType
      this.visible = true
      this.formResourceList()
      this.flowableDefinitionUserTasks()
      setTimeout(() => {
        this.form.setFieldsValue({
          id: record.id,
          processDefinitionId: record.processDefinitionId,
          formId: record.formId,
          nodeType: record.nodeType.toString(),
          actId: record.actId,
          actName: record.actName
        })
      }, 100)
      if (record.nodeType.toString() === '3') {
        this.actIdShow = true
        this.actIdRequired = true
      }
    },
    /**
     * 獲取表單列表
     */
    formResourceList () {
      formResourceList().then((res) => {
        this.formListData = res.data
      })
    },
    /**
     * 表單節點類型
     */
    nodeTypeChange (item) {
      if (item.target.value === '3') {
        this.actIdShow = true
        this.actIdRequired = true
      } else {
        this.actIdShow = false
        this.actIdRequired = false
        setTimeout(() => {
          this.form.setFieldsValue(
            {
              actId: '',
              actName: ''
            }
          )
        }, 100)
      }
    },
    /**
     * 獲取活動節點下拉框數據
     */
    flowableDefinitionUserTasks () {
      this.confirmLoading = true
      flowableDefinitionUserTasks({ id: this.recordData.processDefinitionId }).then((res) => {
        this.confirmLoading = false
        this.actData = res.data
      })
    },
    /**
     * 活動節點選擇事件
     */
    actIdSelectCange (id) {
      this.form.setFieldsValue({ actName: this.actData.find(item => id === item.id).name })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          formEdit(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.visible = false
              this.$emit('ok', values)
              this.form.resetFields()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.actIdShow = false
      this.actIdRequired = false
    }
  }
}
