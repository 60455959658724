import { axios } from '@/utils/request'

/**
 * 表單查詢
 *
 * @author yubaoshan
 * @date 2020/8/15 16:15
 */
export function formResourcePage (parameter) {
  return axios({
    url: '/flowableFormResource/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 表單列表
 *
 * @author yubaoshan
 * @date 2020/8/15 16:15
 */
export function formResourceList (parameter) {
  return axios({
    url: '/flowableFormResource/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 表單添加
 *
 * @author yubaoshan
 * @date 2020/8/15 16:15
 */
export function formResourceAdd (parameter) {
  return axios({
    url: '/flowableFormResource/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯表單
 *
 * @author yubaoshan
 * @date 2020/8/15 16:15
 */
export function formResourceEdit (parameter) {
  return axios({
    url: '/flowableFormResource/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 表單刪除
 *
 * @author yubaoshan
 * @date 2020/8/15 16:15
 */
export function formResourceDelete (parameter) {
  return axios({
    url: '/flowableFormResource/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 表單查看
 *
 * @author yubaoshan
 * @date 2020/8/15 16:15
 */
export function formResourceDetail (parameter) {
  return axios({
    url: '/flowableFormResource/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 表單設計保存
 *
 * @author yubaoshan
 * @date 2020/8/15 21:43
 */
export function formResourceDesign (parameter) {
  return axios({
    url: '/flowableFormResource/design',
    method: 'post',
    data: parameter
  })
}
