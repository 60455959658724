//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis } from '@/components'
import { formList, formDelete } from '@/api/modular/flowable/formManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import detail from './detail'
import addForm from './addForm'
import editForm from './editForm'
export default {
  components: {
    Ellipsis,
    detail,
    addForm,
    editForm
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      tableLoading: false,
      Loading: false,
      columns: [],
      loadData: [],
      // 查詢參數
      queryParam: {},
      // 公用
      nodeTypeData: [],
      typeData: [],
      recordData: [],
      actIdShow: false,
      actIdRequired: false,
      actData: [],
      scriptData: []
    }
  },
  methods: {
    /**
     * 打開頁面初始化
     */
    formIndex (form) {
      this.recordData = form
      this.queryParam.processDefinitionId = form.id
      this.getformList()
      this.addAction()
      this.getDictData()
    },
    /**
     * 初始化table信息
     */
    addAction () {
      this.columns.push(
        {
          title: '表單名稱',
          dataIndex: 'flowableFormResource.name'
        },
        {
          title: '表單節點類型',
          dataIndex: 'nodeType',
          scopedSlots: { customRender: 'nodeType' }
        },
        {
          title: '備註',
          dataIndex: 'flowableFormResource.remark',
          scopedSlots: { customRender: 'flowableFormResourceRemark' }
        }
      )
      if (this.hasPerm('flowableForm:detail') || this.hasPerm('flowableForm:edit') || this.hasPerm('flowableForm:delete')) {
        this.columns.push({
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }
    },
    /**
     * 獲取列表數據
     */
    getformList () {
      this.tableLoading = true
      formList(this.queryParam).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.loadData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 獲取字典參數
     */
    getDictData () {
      sysDictTypeDropDown({ code: 'form_type' }).then((res) => {
        this.nodeTypeData = res.data
      })
    },
    /**
     * 事件節點類型翻譯
     */
    nodeTypeFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.nodeTypeData.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 關閉整個頁面
     */
    handleCancel () {
      this.loadData = []
      this.columns = []
    },
    /**
     * 刪除
     */
    handleDelete (record) {
      this.Loading = true
      formDelete(record).then((res) => {
        this.Loading = false
        if (res.success) {
          this.$message.success('刪除成功')
          this.getformList()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      })
    },
    handleAdd () {
      this.$refs.addForm.add(this.recordData, this.nodeTypeData)
    },
    handleEdit (record) {
      this.$refs.editForm.edit(record, this.nodeTypeData)
    },
    handleDetail (record) {
      this.$refs.detail.open(record)
    },
    refOk () {
      this.getformList()
    }
  }
}
